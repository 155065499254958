<template>
  <v-card>
    <v-card-title
      class="mb-3"
      >
      {{ category.name }}
      <v-spacer></v-spacer>
      <v-icon
        @click="$emit('close')"
        >
        mdi-close
      </v-icon>
    </v-card-title>
    <v-card-text>
      <v-row>
        <template
          v-for="product in products"
          >
          <v-col
            cols="6"
            sm="4"
            class="pa-1"
            >
            <thumb
              slide
              :store="store"
              :content="product"
              ></thumb>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { CategoryProducts } from '@/graphql/queries/categories'

import Thumb from '@/components/Thumb'

export default {
  data () {
    return {
      products: []
    }
  },

  props: {
    category: {
      required: true,
      type: Object
    },
    store: {
      required: true,
      type: Object
    }
  },

  created () {
    this.fetchProducts()
  },

  methods: {
    fetchProducts () {
      this.$apollo.query({
        query: CategoryProducts,
        variables: {
          storeSlug: this.store.slug,
          slug: this.category.slug
        }
      }).then ( res => {
        this.products = res.data.categoryProducts
      })
    }
  },

  components: {
    Thumb
  }
}
</script>
